export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [0,3,4,6];

export const dictionary = {
		"/": [7],
		"/404": [8],
		"/courses/[id]": [10,[2]],
		"/courses/[id]/attendance": [11,[2]],
		"/courses/[id]/certificates": [12,[2]],
		"/courses/[id]/landingpage": [13,[2]],
		"/courses/[id]/lessons": [14,[2]],
		"/courses/[id]/lessons/[...lessonParams]": [~15,[2]],
		"/courses/[id]/marks": [16,[2]],
		"/courses/[id]/people": [17,[2]],
		"/courses/[id]/settings": [18,[2]],
		"/courses/[id]/submissions": [19,[2]],
		"/course/[slug]": [9],
		"/forgot": [20],
		"/home": [21],
		"/invite/s/[hash]": [22,[3]],
		"/invite/t/[hash]": [23,[4]],
		"/lms": [24,[5]],
		"/lms/community": [25,[5]],
		"/lms/community/ask": [27,[5]],
		"/lms/community/[slug]": [26,[5]],
		"/lms/exercises": [28,[5]],
		"/lms/explore": [29,[5]],
		"/lms/mylearning": [30,[5]],
		"/lms/settings": [31,[5]],
		"/login": [32],
		"/logout": [33],
		"/meet": [34],
		"/onboarding": [35],
		"/org/[slug]": [36,[6]],
		"/org/[slug]/audience": [37,[6]],
		"/org/[slug]/community": [38,[6]],
		"/org/[slug]/community/ask": [40,[6]],
		"/org/[slug]/community/[slug]": [39,[6]],
		"/org/[slug]/courses": [41,[6]],
		"/org/[slug]/quiz": [42,[6]],
		"/org/[slug]/quiz/[slug]": [43,[6]],
		"/org/[slug]/settings": [44,[6]],
		"/org/[slug]/settings/customize-lms": [45,[6]],
		"/org/[slug]/settings/domains": [46,[6]],
		"/org/[slug]/settings/teams": [47,[6]],
		"/org/[slug]/setup": [48,[6]],
		"/profile/[id]": [49],
		"/rebuild": [50],
		"/reset": [51],
		"/signup": [52]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';